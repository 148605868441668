import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import { breakpoints as bp } from '@operoute/ui';
import {
  BlueText,
  Container,
  ContentContainer,
  Dot,
  HeadingContainer,
  HorizontalLine,
  ImageContainer,
  LineContainer,
  Number,
  RelationLines,
  Text,
  Title,
  VerticalLine,
  EndDot,
  BottomRelationLines,
} from './common';

const Blocks = styled.div`
  display: grid;
  row-gap: 3rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;

  ${bp[1]} {
    padding-top: 1rem;
    padding-bottom: 1rem;
    row-gap: unset;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
  }

  ${bp[3]} {
    padding-top: 5rem;
  }
`;

const Block = styled.div`
  display: grid;
  row-gap: 0.5rem;
  align-content: flex-start;

  ${bp[1]} {
    row-gap: 1.5rem;
    min-height: 320px;
  }

  ${bp[3]} {
    min-height: 240px;
  }
`;

const BlueLink = styled(BlueText)`
  text-decoration: underline;
`;

const WorkOrders = () => {
  const { image } = useStaticQuery(query);
  return (
    <Container>
      <HeadingContainer>
        <Number>5</Number>
        <Title>Work Orders</Title>
        <Text>Introduce your Part or Product Catalog</Text>
      </HeadingContainer>
      <HorizontalLine />
      <ContentContainer>
        <BlueText selfJustify="flex-start">
          Allows users to track the flow of their part as they become a product
          including operations and results with a complete feedack system for
          Quality and Logistics.
        </BlueText>
        <LineContainer>
          <VerticalLine />
          <Dot />
          <RelationLines />
        </LineContainer>
        <ImageContainer selfJustify="flex-end">
          <Img fluid={image.childImageSharp.fluid} />
        </ImageContainer>
      </ContentContainer>
      <HorizontalLine>
        <Dot left="16%" />
        <BottomRelationLines left="16%" />
        <Dot />
        <BottomRelationLines />
        <Dot left="84%" />
        <BottomRelationLines left="84%" />
      </HorizontalLine>
      <Blocks>
        <Block>
          <Title>NCR</Title>
          <Text>
            Non conformance reports module linked to your operations that lead
            to a potential defect and with Root Cause Analysis functionality and
            work flow for resolution/sign off.
          </Text>
        </Block>
        <Block>
          <Title>Products</Title>
          <Text>
            Create your Product based on a finished / machined part that will
            feed into your Packing Lists and complete the cycle.
          </Text>
        </Block>
        <Block>
          <Title>Packing list</Title>
          <Text>
            Product a complete packing list including your finished products
            following industry best practices.
          </Text>
        </Block>
      </Blocks>
      <HorizontalLine />
      <HeadingContainer>
        <LineContainer height="6rem" style={{ gridRow: '1 / 2 ' }}>
          <VerticalLine />
          <EndDot />
        </LineContainer>
        <BlueLink>
          <Link to="/modules">And more</Link>
        </BlueLink>
      </HeadingContainer>
    </Container>
  );
};

const query = graphql`
  query {
    image: file(
      relativeDirectory: { eq: "modules" }
      name: { eq: "work-orders" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default WorkOrders;
