exports.components = {
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/homepage-query.js" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cancel-js": () => import("./../../../src/pages/cancel.js" /* webpackChunkName: "component---src-pages-cancel-js" */),
  "component---src-pages-fail-js": () => import("./../../../src/pages/fail.js" /* webpackChunkName: "component---src-pages-fail-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-modules-js": () => import("./../../../src/pages/modules.js" /* webpackChunkName: "component---src-pages-modules-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

