import React from 'react';
import styled from '@emotion/styled';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Container = styled.div`
  position: relative;
  display: grid;
  padding-top: 2rem;
`;

const Paragraph = styled.p`
  font-weight: bold;
  font-size: 1.17em;
`;

const Text = styled.p`
  font-weight: 400;
`;

const FailPage = () => {
  return (
    <Layout>
      <SEO
        title="Subscription failed"
        meta={[
          {
            name: 'robots',
            content: 'noindex',
          },
        ]}
      />
      <Container>
        <h1>Subscription failed</h1>
        <Text>
            Hi! We are sorry but something went wrong! Please reach back out to us if you still need to subscribe via our subscription page.
        </Text>
        <Paragraph>
            Hope to see you again soon!
        </Paragraph>
      </Container>
    </Layout>
  );
}

export default FailPage;