import config from '../config';

export const completeSubscriptionRequest = async (id) => {
  try {

    const subscriptionRequest = await fetch(
      `${process.env.GATSBY_AWS_LAMBDA_URL}/subscription/complete`,
      {
        method: 'POST',
        body: JSON.stringify({
          data: {
            StripeId: id
          },
        }),
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.GATSBY_AWS_X_API_KEY
        },
      }
    );

    const response = await subscriptionRequest.json();
    return {
      success: response.success,
      data: response.data,
      error: 'error' in response ? response.error : null,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      error: err.message,
    };
  }
};
