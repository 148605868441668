import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { breakpoints as bp } from '@operoute/ui';
import {
  BlueText,
  Container,
  Dot,
  HeadingContainer,
  HorizontalLine,
  ImageContainer,
  LineContainer,
  Number,
  RelationLines,
  Text,
  Title,
  VerticalLine,
  ContentContainer,
} from './common';

const Parts = () => {
  const { image } = useStaticQuery(query);
  return (
    <Container>
      <HeadingContainer>
        <Number>2</Number>
        <Title>Parts</Title>
        <Text>Introduce your Part Catalog </Text>
      </HeadingContainer>
      <HorizontalLine />
      <ContentContainer>
        <ImageContainer selfJustify="flex-start">
          <Img fluid={image.childImageSharp.fluid} />
        </ImageContainer>
        <LineContainer>
          <VerticalLine />
          <Dot />
          <RelationLines />
        </LineContainer>
        <BlueText selfJustify="flex-end">
          Introduce your Part Catalog to manage as well as its attributes for a
          transparent life cycle management.
        </BlueText>
      </ContentContainer>
    </Container>
  );
};

const query = graphql`
  query {
    image: file(relativeDirectory: { eq: "modules" }, name: { eq: "parts" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Parts;
