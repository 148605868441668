import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import styled from '@emotion/styled';
import { Heading1 } from '../components/common/typography';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { BLACK_COLOR, DARK_GRAY_COLOR, YELLOW_COLOR } from '@operoute/ui';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const Text = styled.p`
  font-weight: 400;
  font-size: 1.125rem;
  color: ${DARK_GRAY_COLOR};
`;

const List = styled.ul``;

const ListItem = styled.li`
  font-size: 1.125rem;
  font-weight: 400;
  color: ${BLACK_COLOR};

  &::marker {
    color: ${YELLOW_COLOR};
    font-size: 1.5em;
  }
`;

const Container = styled.div`
  position: relative;
  display: grid;
  padding-top: 3rem;
`;

const GatsbyImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const ModulesPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Modules" />
      <div>
        <Heading1>Modules Map</Heading1>
        <Text>
          Please review the diagram of modules included in Operoute and
          potential benefits of implementing such as:
        </Text>
        <List>
          <ListItem>
            Integration and visibility of events and production floor data
            enables more flexibility for suppliers to make workflow decisions
          </ListItem>
          <ListItem>
            Calling Data to Action: Aggregation of data provides the platform to
            infuse analytics to optimize production process
          </ListItem>
          <ListItem>
            Digital Shift: COVID-19 accelerated digital transformation of supply
            chains and manufacturers are moving 25 to 30 times faster at
            incorporating technology into their supply chains
          </ListItem>
        </List>
        <Container>
          <Zoom>
            <GatsbyImage fluid={data.modulesFullFlow.fluid} />
          </Zoom>
        </Container>
      </div>
    </Layout>
  );
};

export default ModulesPage;

export const query = graphql`
  query {
    modulesFullFlow: imageSharp(
      fluid: { originalName: { eq: "full-flow.png" } }
    ) {
      fluid(quality: 100, maxWidth: 1248) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
