import React from 'react';
import { navigate } from 'gatsby';
import WhiteLogo from '../../../images/whiteLogo.svg';

const handleClick = () => {
  navigate('/');
};

const Logo = () => (
  <img
    src={WhiteLogo}
    alt="Rocketseat logo"
    style={{ width: 180 }}
    onClick={handleClick}
  />
);

export default Logo;
