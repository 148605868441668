import styled from '@emotion/styled';
import { LIGHT_GRAY_COLOR, mapColor, WHITE_COLOR } from './common/color';

export const NotificationsWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: grid;
  row-gap: 1rem;
`;

const Notification = styled.div`
  --notification-bg-color: ${mapColor(LIGHT_GRAY_COLOR, 'type')};
  --notification-color: ${mapColor(WHITE_COLOR, 'color')};
  position: relative;
  display: inline-flex;
  padding: 0.5rem 1rem;
  column-gap: 1rem;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 700;
  background-color: var(--notification-bg-color);
  color: var(--notification-color);
  border-radius: 5px;
  box-sizing: border-box;
  z-index: 10000;
`;

export default Notification;
