import React from 'react';
import styled from '@emotion/styled';
import { GRAY_COLOR, mapColor } from './common/color';

const Wrapper = styled.div`
  --progress-bar-color: ${mapColor(GRAY_COLOR)};
  display: grid;
  max-width: 320px;
  width: 100%;
  min-width: 120px;
  row-gap: 0.5rem;
`;

const Container = styled.div<{ percentage: number }>`
  width: ${(props) => `${props.percentage}%`};
  height: 0.375rem;
  border: 1px solid var(--progress-bar-color);
  border-radius: 1px;
  box-sizing: border-box;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--progress-bar-color);
`;

const Text = styled.div`
  text-transform: uppercase;
  font-size: 0.5rem;
`;

const ProgressBar = ({ color = 'default', percentage = 100, text = '' }) => {
  return (
    <Wrapper color={color}>
      <Container percentage={percentage}>
        <Content />
      </Container>
      <Text>{text}</Text>
    </Wrapper>
  );
};

export default ProgressBar;
