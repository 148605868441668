import React, { createContext, useState, useEffect, useMemo } from 'react';
import Notification, { NotificationsWrapper } from '../components/notification';

export const NotificationsContext = createContext({
  notifications: [],
  addNotification: () => null,
});

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = ({ type = 'success', message }) => {
    setNotifications((prev) => prev.concat([{ type, message }]));
  };

  const value = useMemo(
    () => ({
      notifications,
      addNotification,
    }),
    [notifications]
  );

  useEffect(() => {
    const msgs = [...notifications];
    const timer = setTimeout(() => {
      if (msgs.length) {
        msgs.shift();
        setNotifications(msgs);
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [notifications]);

  return (
    <NotificationsContext.Provider value={value}>
      <NotificationsWrapper>
        {notifications.slice(0, 5).map((notif, index) => (
          <Notification key={`some-id-${index}`} type={notif.type}>
            {notif.message}
          </Notification>
        ))}
      </NotificationsWrapper>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
