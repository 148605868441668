import styled from '@emotion/styled';
import { mapColor, breakpoints as bp } from '@operoute/ui';

export const HeroTitle = styled.h1`
  margin: 0;
  color: ${mapColor()};
  font-family: var(--primary-font);
  font-size: 2rem;
  font-weight: 800;
  text-transform: uppercase;

  ${bp[0]} {
    font-size: 2.5rem;
  }
`;

export const Heading1 = styled.h1`
  margin: 0;
  color: ${mapColor()};
  font-family: var(--secondary-font);
  font-size: 2rem;
  font-weight: 800;
  text-transform: uppercase;

  ${bp[0]} {
    font-size: 2.5rem;
  }
`;

export const Heading2 = styled.h2`
  margin: 0;
  color: ${mapColor()};
  font-family: var(--primary-font);
  font-size: 2rem;
  font-weight: 300;

  ${bp[0]} {
    font-size: 2.25rem;
  }
`;

export const Heading3 = styled.h3`
  margin: 0;
  color: ${mapColor()};
  font-family: var(--primary-font);
  font-size: 1.125rem;
  font-weight: 300;

  ${bp[0]} {
    font-size: 1.5rem;
  }
`;

export const Heading4 = styled.h4`
  margin: 0;
  color: ${mapColor()};
  font-family: var(--secondary-font);
  font-size: 1.5rem;
  font-weight: 300;
`;

export const Text = styled.p``;
