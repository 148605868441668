import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import {
  Container,
  HeadingContainer,
  LineContainer,
  StartDot,
  VerticalLine,
  Number,
  Title,
  Text,
  HorizontalLine,
  ContentContainer,
  BlueText,
  Dot,
  RelationLines,
  ImageContainer,
} from './common';

const Planning = () => {
  const { image } = useStaticQuery(query);
  return (
    <Container>
      <HeadingContainer>
        <LineContainer height="6rem" style={{ gridRow: 'unset' }}>
          <StartDot />
          <VerticalLine />
        </LineContainer>
        <Number>1</Number>
        <Title>Planning</Title>
        <Text>
          Plan your production in a fast and easy way and feed this to your shop
          floor to ensure timely delivery of your products.
        </Text>
      </HeadingContainer>
      <HorizontalLine />
      <ContentContainer>
        <BlueText selfJustify="flex-start">
          Put together the work plan by week or month, taking into account
          resources such as machine, part, operator and shift
        </BlueText>
        <LineContainer>
          <VerticalLine />
          <Dot />
          <RelationLines />
        </LineContainer>
        <ImageContainer selfJustify="flex-end">
          <Img fluid={image.childImageSharp.fluid} />
        </ImageContainer>
      </ContentContainer>
    </Container>
  );
};

const query = graphql`
  query {
    image: file(
      relativeDirectory: { eq: "modules" }
      name: { eq: "planning" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Planning;
