import React from 'react';
import styled from '@emotion/styled';
import { DARK_GRAY_COLOR, mapColor } from './common/color';

const ChipSpan = styled.span<ChipSpanProps>`
  --chip-color: ${mapColor(DARK_GRAY_COLOR)};
  position: relative;
  display: inline-flex;
  padding: 0.25rem 1rem 0.25rem 1.5rem;
  min-height: 1.5rem;
  width: auto;
  max-width: 150px;
  color: var(--black-color);
  border: 1px solid var(--chip-color);
  border-radius: 5px;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 0.625rem;
  font-family: var(--secondary-font);

  content: '\25CF';
  position: absolute;
  top: -2px;
  left: 0;
  height: 100%;
  transform: translate(0.5rem, 50%);
  color: var(--chip-color);
  font-size: 1rem;
  line-height: 0;
`;

type Types = 'default' | 'success' | 'warning' | 'danger';

type ChipSpanProps = {
  type: Types;
};

interface Props {
  type: Types;
  text: string;
}

const Chip = ({ type = 'default', text }: Props) => {
  return <ChipSpan type={type}>{text}</ChipSpan>;
};

export default Chip;
