import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';

const Section = styled.section`
  display: grid;
  row-gap: 1rem;
`;



const VideoContainer = styled.div`
.video {
    aspect-ratio: 16 / 9;
    width: 100%;
  }
`;



const VideoBlock = () => {
    return (
        <Section id="videoglam">
            <VideoContainer>
            <iframe
            class="video"
            src="https://www.youtube.com/embed/nEOgDm-UZAM"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope;"
            allowfullscreen></iframe>
            </VideoContainer>

        </Section>
    );
};   


  
export default VideoBlock;

