import React from 'react';
import styled from '@emotion/styled';
import { Heading1, Heading2 } from '../common/typography';
import { Paper } from '../common/shape';
import {
  breakpoints as bp,
  LIGHT_GRAY_COLOR,
  GRAY_COLOR_RGBA,
  DARK_GRAY_COLOR,
  ButtonLink,
  DARK_BLUE_COLOR,
  YELLOW_COLOR,
} from '@operoute/ui';

const Section = styled.section`
  display: grid;
  row-gap: 1rem;
`;

const PricingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(300px, 1fr));
  column-gap: 1rem;
  row-gap: 2rem;

  ${bp[0]} {
    grid-template-columns: repeat(1, minmax(340px, 1fr));
  }

  ${bp[2]} {
    grid-template-columns: repeat(1, minmax(360px, 1fr));
    column-gap: 1.5rem;
    row-gap: 3rem;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 400px;
      margin-top: -1.5rem;
      left: 0;
      background-color: ${DARK_BLUE_COLOR};
      z-index: -2;
    }
  }

  ${bp[3]} {
    column-gap: 4rem;
    padding-top: 4rem;

    &::after {
      content: '';
      position: absolute;
      width: 1300px;
      height: 400px;
      margin-top: 1.5rem;
      left: 50%;
      transform: translateX(-50%);
      border: 2px solid ${YELLOW_COLOR};
      border-radius: 10px;
      z-index: -1;
    }
  }
`;

const PricingPaper = styled(Paper)`
  display: grid;
  grid-template-rows: auto 1fr auto;
  row-gap: 1rem;
  height: 400px;
  padding: 2rem;
  max-width: 320px;
  width: 100%;
  justify-self: center;
  box-sizing: border-box;

  ${bp[0]} {
    max-width: 360px;
  }

  ${bp[1]} {
    ${(props) =>
      props.customblock
        ? `grid-column-end: 3;
    grid-column-start: 1;`
        : ''}
  }

  ${bp[3]} {
    ${(props) =>
      props.customblock
        ? `grid-column-end: unset;
    grid-column-start: unset;`
        : ''}
  }
`;

const PricingHeader = styled.div`
  display: inline-flex;
  align-items: baseline;
  justify-content: space-between;
  box-sizing: border-box;
`;

const PricingContent = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  row-gap: 1rem;
  padding: 1rem;
  border: 1px solid ${GRAY_COLOR_RGBA(0.8)};
  border-radius: 10px;
  background-color: ${LIGHT_GRAY_COLOR};
`;

const PricingContentTitle = styled.span`
  position: relative;
  font-size: 3rem;
  font-family: var(--secondary-font);
  font-weight: 700;

  &:before {
    content: ${(props) => (props.isprice ? '"$"' : 'unset')};
    position: absolute;
    top: 0.8rem;
    left: -0.8rem;
    font-size: 1rem;
  }
`;

const PricingButton = styled(ButtonLink)`
  align-self: flex-end;
  justify-content: center;
  border-radius: 10px;
`;

const PricingSmallText = styled.span`
  max-width: 240px;
  font-size: 0.75rem;
  text-align: ${(props) => (props.center ? 'center' : 'start')};
`;

const PricingBlock = () => {
  return (
    <Section id="pricing">
      <Heading1 style={{ justifySelf: 'center' }}>Pricing</Heading1>
      <Heading2 style={{ justifySelf: 'center', textAlign: 'center' }}>
        "Choose the pricing model that fits best for your needs at this point in
        time". You can switch at any moment
      </Heading2>
      <PricingContainer>
        <PricingPaper>
          <PricingHeader>
            <strong>Operoute Core</strong>
            <PricingSmallText>Billing monthly</PricingSmallText>
          </PricingHeader>
          <PricingContent>
            <PricingContentTitle isprice>80</PricingContentTitle>
            <span>Per User per month</span>
            <span></span>
            <PricingSmallText center>
              All features such as parts, work orders, purchase orders, route
              cards, packing lists
            </PricingSmallText>
          </PricingContent>
          <PricingButton to="/signup" bgcolor="primary" color="white">
            Sign Up
          </PricingButton>
        </PricingPaper>
      </PricingContainer>
    </Section>
  );
};

export default PricingBlock;
