import styled from '@emotion/styled';
import { GRAY_COLOR, LIGHT_GRAY_COLOR, WHITE_COLOR } from './color';

export const ContentContainer = styled.main`
  position: relative;
  background-color: ${LIGHT_GRAY_COLOR};
  padding: 1rem;
  overflow-y: scroll;
`;

export const Content = styled.div`
  display: grid;
  column-gap: 1rem;
  row-gap: 1.5rem;
`;

export const PaperTitle = styled.h5`
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  line-height: normal;
`;

export const Paper = styled.div`
  width: 100%;
  padding: 2rem 1.5rem;
  background-color: ${WHITE_COLOR};
  border: 1px solid ${GRAY_COLOR};
  border-left-width: 6px;
  border-radius: 5px;
  box-sizing: border-box;
`;

export const InnerPaper = styled.div`
  background-color: ${LIGHT_GRAY_COLOR};
  border: unset;

  & > ${PaperTitle} {
    margin-bottom: 2rem;
  }
`;
