import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Header from './header';
import Contact from './contact';
import { breakpoints as bp, DARK_BLUE_COLOR, GlobalStyles } from '@operoute/ui';

const MainContainer = styled.main`
  display: grid;
  row-gap: 4rem;
  grid-template-columns: 90%;
  justify-content: center;
  z-index: 1;

  ${bp[0]} {
    row-gap: 12rem;
    padding-top: unset;
  }

  ${bp[1]} {
    grid-template-columns: 100%;
    width: 100%;
    max-width: 95%;
    margin: 0 auto;
  }

  ${bp[2]} {
    width: 90%;
  }

  ${bp[3]} {
    margin-top: 4rem;
    max-width: 1248px;
  }
`;

const Footer = styled.footer`
  padding-bottom: 12rem;
`;

const Background = styled.div`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
`;

const LinearGradientBG = styled.div`
  position: relative;
  width: 100%;

  height: 100%;
  background: linear-gradient(
    180deg,
    #202b3a 0%,
    rgba(32, 43, 58, 0.988803) 19.3%,
    rgba(32, 43, 58, 0.955679) 20.52%,
    rgba(32, 43, 58, 0.882074) 28.87%,
    rgba(32, 43, 58, 0.576584) 45.03%,
    rgba(32, 43, 58, 0.412408) 55.64%,
    rgba(32, 43, 58, 0.258038) 66.25%,
    rgba(32, 43, 58, 0.174023) 75.63%,
    rgba(32, 43, 58, 0.0937016) 85.23%,
    rgba(32, 43, 58, 0.0692278) 90.97%,
    rgba(32, 43, 58, 0.0193554) 96.75%,
    rgba(32, 43, 58, 0) 101.32%
  );
`;

const BackgroundImage = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: ${(props) => `url("${props.imagesrc}")`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const Layout = ({ children, backgroundImage = null }) => {
  const { site } = useStaticQuery(query);

  return (
    <>
      <GlobalStyles />
      <Header
        hasimage={!!backgroundImage}
        siteTitle={site.siteMetadata.title || `Operoute`}
      />
      <Background visible={!!backgroundImage}>
        <LinearGradientBG />
        {backgroundImage && (
          <BackgroundImage imagesrc={backgroundImage.resize.src} />
        )}
      </Background>
      <MainContainer>
        {children}
        <Footer>
          <Contact />
          {/* © {new Date().getFullYear()}, Built with
          <a href="https://www.gatsbyjs.com">Gatsby</a> */}
        </Footer>
      </MainContainer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Layout;
