import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { breakpoints as bp } from '@operoute/ui';
import {
  BlueText,
  Container,
  ContentContainer,
  Dot,
  HeadingContainer,
  HorizontalLine,
  ImageContainer,
  LineContainer,
  Number,
  RelationLines,
  Text,
  Title,
  VerticalLine,
} from './common';

const Blocks = styled.div`
  display: grid;
  grid-template-rows: repeat(4, auto);
  row-gap: 1rem;
  align-self: center;
  justify-self: center;
  max-width: 480px;
  width: 100%;

  ${bp[1]} {
    grid-template-rows: repeat(4, 1fr);
    justify-self: flex-end;
  }

  ${bp[2]} {
    max-width: 435px;
    padding-top: 1rem;
  }

  ${bp[3]} {
    padding-top: unset;
    grid-template-rows: repeat(4, 1fr);
    max-width: 480px;
    row-gap: 1rem;
  }
`;

const BlockText = styled.p`
  margin: 0;
  margin-top: 0.3rem;
  color: #999999;
  font-size: 1.5rem;
  font-weight: 300;
  font-family: var(--primary-font);
`;

const RouteCard = () => {
  const { image } = useStaticQuery(query);
  return (
    <Container>
      <HeadingContainer>
        <Number>4</Number>
        <Title>Route Card</Title>
        <Text>
          Operation guides to allow users to follow the appropriate and
          "approved" flow for the producton of your parts/products.
        </Text>
      </HeadingContainer>
      <HorizontalLine />
      <ContentContainer>
        <ImageContainer selfJustify="flex-start">
          <Img fluid={image.childImageSharp.fluid} />
        </ImageContainer>
        <LineContainer>
          <VerticalLine />
          <Dot top="42.5%" left="-5.4rem" />
          <Dot top="9%" />
          <RelationLines
            top="9%"
            leftLine={{
              right: '12px',
              transformOrigin: 'center right',
              transform: 'rotate(-67.5deg)',
              width: '205px',
            }}
          />
          <Dot top="31%" />
          <RelationLines
            top="31%"
            leftLine={{
              right: '12px',
              transformOrigin: 'center right',
              transform: 'rotate(-40deg)',
            }}
          />
          <Dot top="54%" />
          <RelationLines
            top="54%"
            leftLine={{
              right: '12px',
              transformOrigin: 'center right',
              transform: 'rotate(40deg)',
            }}
          />
          <Dot top="76%" />
          <RelationLines
            top="76%"
            leftLine={{
              right: '12px',
              transformOrigin: 'center right',
              transform: 'rotate(67.5deg)',
              width: '205px',
            }}
          />
        </LineContainer>
        <Blocks>
          <div>
            <BlueText>Operations</BlueText>
            <BlockText>
              Configure operations including operation standards, work
              instructions, list of tools, etc. to associate with a Work Route.
            </BlockText>
          </div>
          <div>
            <BlueText>Work Instructions</BlueText>
            <BlockText>
              They are the detailed steps that form an operation
            </BlockText>
          </div>
          <div>
            <BlueText>Operation Inputs</BlueText>
            <BlockText>
              Data inputs by the user that can go into an operation.
            </BlockText>
          </div>
          <div>
            <BlueText>Tool and Fixtures</BlueText>
            <BlockText>
              Set up the list of specialized tools and fixtures to produce your
              work piece and hold it firmly inside the work machine.
            </BlockText>
          </div>
        </Blocks>
      </ContentContainer>
    </Container>
  );
};

const query = graphql`
  query {
    image: file(
      relativeDirectory: { eq: "modules" }
      name: { eq: "route-card" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default RouteCard;
