import React from 'react';
import { Global, css } from '@emotion/react';

export const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        :root {
          /* Palette */
          --primary-color: #1b98e0;
          --secondary-color: #ffffff;
          --success-color: #0eab8c;
          --warning-color: #f0c808;
          --danger-color: #da5d54;
          --gray-color: #eaeaea;
          --dark-gray-color: #7a7a7a;
          --light-gray-color: #f8f8f8;
          --white-color: #ffffff;
          --black-color: #3d3d3e;
          --yellow-color: #f0c808;
          --dark-blue-color: #202b3a;
          --light-blue-color: #2c394a;
          --darker-blue-color: #131e2c;
          /* RGB Palette */
          --primary-color-rgb: 27, 152, 224;
          --secondary-color-rgb: 255, 255, 255;
          --success-color-rgb: 14, 171, 140;
          --warning-color-rgb: 240, 200, 8;
          --danger-color-rgb: 218, 93, 84;
          --gray-color-rgb: 234, 234, 234;
          --dark-gray-color-rgb: 122, 122, 122;
          --light-gray-color-rgb: 248, 248, 248;
          --white-color-rgb: 255, 255, 255;
          --black-color-rgb: 61, 61, 62;
          --yellow-color-rgb: 240, 200, 8;
          --dark-blue-color-rgb: 32, 43, 58;
          --light-blue-color-rgb: 44, 57, 74;
          /* Fonts */
          --primary-font: Roboto, sans-serif;
          --secondary-font: 'K2D', sans-serif;
        }

        html,
        body {
          margin: 0;
          font-family: var(--primary-font);
          color: var(--black-color);
          overflow-x: hidden;
          scroll-behavior: smooth;
        }

        a,
        a:hover,
        a:focus,
        a:active,
        a:visited {
          --link-color: var(--primary-color);
          color: var(--link-color);
          text-decoration: none;
        }

        ::placeholder {
          /* Most modern browsers support this now. */
          font-family: var(--secondary-font);
          color: var(--dark-gray-color);
          opacity: 0.6;
        }
      `}
    />
  );
};

export default GlobalStyles;
