import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { breakpoints as bp } from '@operoute/ui';
import {
  Container,
  HeadingContainer,
  HorizontalLine,
  Number,
  Text,
  Title,
  ContentContainer,
  BlueText,
  LineContainer,
  VerticalLine,
  Dot,
  RelationLines,
  ImageContainer,
} from './common';

const PurchaseOrders = () => {
  const { image } = useStaticQuery(query);
  return (
    <Container>
      <HeadingContainer>
        <Number>3</Number>
        <Title>Purchase Orders</Title>
        <Text>Create and manage your Client Purchase Orders</Text>
      </HeadingContainer>
      <HorizontalLine />
      <ContentContainer>
        <BlueText selfJustify="flex-start">
          Create and manage your Client Purchase Orders as well as list of
          parts/products to kick off a production process in the system.
        </BlueText>
        <LineContainer>
          <VerticalLine />
          <Dot />
          <RelationLines />
        </LineContainer>
        <ImageContainer selfJustify="flex-end">
          <Img fluid={image.childImageSharp.fluid} />
        </ImageContainer>
      </ContentContainer>
    </Container>
  );
};

const query = graphql`
  query {
    image: file(
      relativeDirectory: { eq: "modules" }
      name: { eq: "purchase-orders" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default PurchaseOrders;
