export const MOBILE_DEVICE = 576;
export const TABLET_PORTRAIT_DEVICE = 768;
export const TABLET_LANDSCAPE_DEVICE = 992;
export const DESKTOP_DEVICE = 1240;

export const BREAKPOINTS = [
  MOBILE_DEVICE,
  TABLET_PORTRAIT_DEVICE,
  TABLET_LANDSCAPE_DEVICE,
  DESKTOP_DEVICE,
];

export const breakpoints = BREAKPOINTS.map(
  (bp) => `@media (min-width: ${bp}px)`
);

export default breakpoints;
