import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { Heading4, HeroTitle } from '../common/typography';
import { breakpoints as bp, ButtonLink } from '@operoute/ui';

const Section = styled.section`
  display: grid;
  min-height: calc(100vh - 6rem); // 6rem size of header
  align-items: center;

  ${bp[1]} {
    min-height: 480px;
    padding-bottom: 200px;
  }
`;

const Content = styled.div`
  position: relative;
  display: grid;
  align-items: stretch;
  row-gap: 1rem;
  height: auto;
  z-index: 10;

  ${bp[0]} {
    row-gap: 1.5rem;
    max-width: 680px;
  }
`;

const ImgContainer = styled.div`
  display: none;
  justify-self: center;
  max-width: 660px;
  width: 100%;
  height: auto;

  ${bp[2]} {
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-35%);
  }

  ${bp[3]} {
    transform: translateY(-45%);
  }
`;

const MainButton = styled(ButtonLink)`
  padding-left: 3.5rem;
  padding-right: 3.5rem;
`;

const MainButtonContainer = styled.div`
  padding-top: 1rem;

  ${bp[0]} {
    padding-top: 2rem;
  }
`;

const MainBlock = () => {
  const { mainImage } = useStaticQuery(query);

  return (
    <Section>
      <Content>
        <HeroTitle color="white">
          Keep track of your Production Orders and Dominate your Product Life Cycle NOW!
        </HeroTitle>
        <Heading4 color="white">Data to action in the manufacturing world</Heading4>
        <MainButtonContainer>
          <MainButton
            color="white"
            bgcolor="primary"
            style={{ borderRadius: '20px', padding: '1.625rem 4.5rem' }}
            to="/#contact"
          >
            Get Started
          </MainButton>
        </MainButtonContainer>
      </Content>
      <ImgContainer>
        <Img fluid={mainImage.fluid} />
      </ImgContainer>
    </Section>
  );
};

const query = graphql`
  query {
    mainImage: imageSharp(
      fluid: { originalName: { eq: "laptop-dashboard.png" } }
    ) {
      fluid(maxWidth: 900) {
        ...GatsbyImageSharpFluid
      }
    }
    backgroundImage: imageSharp(
      fluid: { originalName: { eq: "main-background.png" } }
    ) {
      fluid(maxHeight: 660) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export default MainBlock;
