import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import {
  breakpoints as bp,
  Button,
  ButtonLink,
  DARKER_BLUE_COLOR,
  DARK_BLUE_COLOR,
  YELLOW_COLOR,
} from '@operoute/ui';

import { ReactComponent as HamburgerSVG } from '../icons/hamburger.svg';
import { ReactComponent as CancelSVG } from '../icons/cancel.svg';

const HeaderContainer = styled.header`
  position: relative;
  display: grid;
  justify-content: center;
  grid-template-columns: 100%;
  top: 0;
  height: 6rem;
  width: 100vw;
  z-index: 100;
  background-color: ${(props) => (!props.hasimage ? DARK_BLUE_COLOR : `unset`)};

  ${bp[1]} {
    padding-top: 2rem;
    padding-bottom: 2rem;
    grid-template-columns: 95%;
  }

  ${bp[2]} {
    grid-template-columns: 90%;
  }

  ${bp[3]} {
    grid-template-columns: 1248px;
  }
`;

const NavContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 2rem;
  align-items: center;
  padding: 1.5rem;
  width: 100%;

  font-family: var(--secondary-font);
  font-size: 0.875rem;
  box-sizing: border-box;
  /* box-shadow: 0px 4px 10px -1px rgba(var(--black-color-rgb), 0.25); */

  ${bp[1]} {
    grid-template-columns: auto 1fr auto;
    column-gap: 0.5rem;
    padding: 1rem;

    border: 3px solid ${YELLOW_COLOR};
    border-radius: 20px;
  }

  ${bp[2]} {
    column-gap: 2rem;
    padding: 1.5rem;
  }
`;
const Menu = styled.nav`
  display: none;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-content: start;

  ${bp[1]} {
    display: grid;
    column-gap: 0.5rem;
  }

  ${bp[2]} {
    column-gap: 1rem;
  }
`;

const AuthOptions = styled.div`
  display: none;
  grid-auto-flow: column;
  justify-self: flex-end;

  ${bp[1]} {
    display: grid;
    column-gap: 0.5rem;
  }

  ${bp[2]} {
    column-gap: 1rem;
  }
`;

const MobileOpenButtonMenu = styled.div`
  display: grid;
  justify-self: flex-end;

  ${bp[1]} {
    display: none;
  }
`;

const MobileMenuNav = styled.div`
  position: fixed;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  background-color: ${DARKER_BLUE_COLOR};
  font-size: 0.875rem;
  z-index: 999;
  box-sizing: border-box;

  ${bp[1]} {
    display: none;
  }
`;

const MobileMenuContainer = styled.div`
  display: grid;
`;

const MobileCloseButtonMenu = styled(Button)`
  justify-self: flex-end;
  align-self: flex-start;
  padding: 0;
`;

const MobileMenu = styled.div`
  display: grid;
  row-gap: 1rem;
`;

const MenuItems = ({ setOpenMobileMenu = () => null }) => (
  <>
    <ButtonLink
      color="white"
      isNoStyle
      to="/#modules"
      onClick={() => setOpenMobileMenu(false)}
    >
      Modules
    </ButtonLink>
    <ButtonLink
      color="white"
      isNoStyle
      to="/#pricing"
      onClick={() => setOpenMobileMenu(false)}
    >
      Pricing
    </ButtonLink>
    <ButtonLink
      color="white"
      isNoStyle
      to="/#contact"
      onClick={() => setOpenMobileMenu(false)}
    >
      Contact Sales
    </ButtonLink>
    <ButtonLink color="white" isNoStyle>
      <a style={{ color: 'white' }} href="/documentation">
        Documentation
      </a>
    </ButtonLink>
  </>
);

const AuthItems = () => {
  return (
    <>
      <ButtonLink as="a" isNoStyle color="primary" href={ process.env.APP_OPEROUTE_URL }>
        Log In
      </ButtonLink>
      <ButtonLink
        color="white"
        bgcolor="primary"
        style={{ borderRadius: '20px', padding: '0.5rem 1.5rem' }}
        to="/signup"
      >
        Sign Up
      </ButtonLink>
    </>
  );
};

const Header = ({ siteTitle, hasimage = false }) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const { logo } = useStaticQuery(query);
  return (
    <>
      <HeaderContainer hasimage={hasimage}>
        <NavContainer>
          <Link to="/" title={siteTitle}>
            <Img fixed={logo.fixed} />
          </Link>
          <Menu>
            <MenuItems />
          </Menu>

          <AuthOptions>
            <AuthItems />
          </AuthOptions>
          <MobileOpenButtonMenu>
            <Button
              istransparent
              isNoStyle
              style={{ padding: '0' }}
              onClick={() => setOpenMobileMenu((prev) => !prev)}
            >
              <HamburgerSVG />
            </Button>
          </MobileOpenButtonMenu>
        </NavContainer>
      </HeaderContainer>
      <MobileMenuNav visible={openMobileMenu}>
        <MobileMenuContainer>
          <MobileCloseButtonMenu
            istransparent
            isNoStyle
            onClick={() => setOpenMobileMenu((prev) => !prev)}
          >
            <CancelSVG />
          </MobileCloseButtonMenu>
          <MobileMenu>
            <MenuItems setOpenMobileMenu={setOpenMobileMenu} />
            <AuthItems />
          </MobileMenu>
        </MobileMenuContainer>
      </MobileMenuNav>
    </>
  );
};

const query = graphql`
  query {
    logo: imageSharp(fixed: { originalName: { eq: "full-logo.png" } }) {
      id
      fixed(height: 35) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
