export const PRIMARY_COLOR = 'var(--primary-color)';
export const SECONDARY_COLOR = 'var(--secondary-color)';
export const GRAY_COLOR = 'var(--gray-color)';
export const DARK_GRAY_COLOR = 'var(--dark-gray-color)';
export const LIGHT_GRAY_COLOR = 'var(--light-gray-color)';
export const BLACK_COLOR = 'var(--black-color)';
export const WHITE_COLOR = 'var(--white-color)';

export const YELLOW_COLOR = 'var(--yellow-color)';
export const DARK_BLUE_COLOR = 'var(--dark-blue-color)';
export const DARKER_BLUE_COLOR = 'var(--darker-blue-color)';
export const LIGHT_BLUE_COLOR = 'var(--light-blue-color)';

export const SUCCESS_COLOR = 'var(--success-color)';
export const WARNING_COLOR = 'var(--warning-color)';
export const DANGER_COLOR = 'var(--danger-color)';

export const PRIMARY_COLOR_RGBA = (opacity) =>
  `rgba(var(--primary-color-rgb), ${opacity})`;
export const SECONDARY_COLOR_RGBA = (opacity) =>
  `rgba(var(--secondary-color-rgb), ${opacity})`;
export const GRAY_COLOR_RGBA = (opacity) =>
  `rgba(var(--gray-color-rgb), ${opacity})`;
export const LIGHT_GRAY_COLOR_RGBA = (opacity) =>
  `rgba(var(--light-gray-color-rgb), ${opacity})`;
export const DARK_GRAY_COLOR_RGBA = (opacity) =>
  `rgba(var(--dark-gray-color-rgb), ${opacity})`;
export const BLACK_COLOR_RGBA = (opacity) =>
  `rgba(var(--black-color-rgb), ${opacity})`;
export const WHITE_COLOR_RGBA = (opacity) =>
  `rgba(var(--white-color-rgb), ${opacity})`;

export const colors = {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  GRAY_COLOR,
  BLACK_COLOR,
  WHITE_COLOR,
};

export const mapColor = (def = '', prop = 'color') => (props) => {
  switch (props[prop]) {
    case 'primary':
      return PRIMARY_COLOR;
    case 'secondary':
      return SECONDARY_COLOR;
    case 'success':
      return SUCCESS_COLOR;
    case 'warning':
      return WARNING_COLOR;
    case 'danger':
    case 'error':
      return DANGER_COLOR;
    case 'gray':
      return GRAY_COLOR;
    case 'dark_gray':
      return DARK_GRAY_COLOR;
    case 'white':
      return WHITE_COLOR;
    case 'black':
    case 'default':
    default:
      if (def) {
        return def;
      }
      return BLACK_COLOR;
  }
};

export const mapBackground = (props) => {
  switch (props.bgcolor) {
    case 'secondary':
      return SECONDARY_COLOR;
    case 'gray':
      return GRAY_COLOR;
    case 'white':
      return WHITE_COLOR;
    case 'black':
      return BLACK_COLOR;
    case 'primary':
    default:
      return PRIMARY_COLOR;
  }
};

export const mapbordercolor = (props) => {
  switch (props.bgcolor) {
    case 'secondary':
      return SECONDARY_COLOR;
    case 'gray':
      return GRAY_COLOR;
    case 'white':
      return GRAY_COLOR_RGBA(0.6);
    case 'black':
      return BLACK_COLOR;
    case 'primary':
    default:
      return PRIMARY_COLOR;
  }
};
