import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import {
  DARK_GRAY_COLOR,
  GRAY_COLOR,
  LIGHT_GRAY_COLOR,
  mapColor,
  YELLOW_COLOR,
} from './color';

const setBorderColor = (props) => {
  let temp = props.bordercolor;
  if (!props.istransparent) {
    temp = props.bgcolor ? props.bgcolor : props.bordercolor;
  } else if (props.istransparent && !temp) {
    return 'transparent';
  }

  return mapColor(
    GRAY_COLOR,
    'bordercolor'
  )({
    ...props,
    bordercolor: temp,
  });
};

const setBackgroundColor = (props) => {
  if (props.istransparent) {
    return 'unset';
  }

  return mapColor(LIGHT_GRAY_COLOR, 'bgcolor')(props);
};

export const ButtonNoStyle = styled.button`
  --link-color: ${mapColor()};
  --button-color: ${mapColor(DARK_GRAY_COLOR)};
  --button-bg-color: transparent;
  --button-border-color: transparent;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0.875rem 1rem;
  height: 42px;
  color: var(--button-color) !important;
  font-size: 0.875rem;
  background-color: var(--button-bg-color);
  border: 1px solid var(--button-border-color);
  cursor: pointer;
  box-sizing: border-box;
`;

export const Button = styled.button`
  --link-color: ${mapColor()};
  --button-bg-color: ${setBackgroundColor};
  --button-color: ${mapColor()};
  --button-border-color: ${setBorderColor};
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0.875rem 1rem;
  height: 42px;
  color: var(--button-color) !important;
  font-size: 0.875rem;
  font-weight: 700;
  font-family: inherit;
  background-color: var(--button-bg-color);
  border: 1px solid var(--button-border-color);
  cursor: pointer;
  box-sizing: border-box;

  &:focus {
    outline-color: ${YELLOW_COLOR};
  }

  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
`;

export const InternalButtonLinkNoStyle = ButtonNoStyle.withComponent(Link);

export const InternalButtonLink = Button.withComponent(Link);

export const ExternalButtonLinkNoStyle = ButtonNoStyle.withComponent('a');

export const ExternalButtonLink = Button.withComponent('a');

export const ButtonLink = ({
  children,
  to,
  isExternal = false,
  isNoStyle = false,
  ...rest
}) => {
  if (isExternal) {
    if (isNoStyle) {
      return (
        <ExternalButtonLinkNoStyle href={to} {...rest}>
          {children}
        </ExternalButtonLinkNoStyle>
      );
    }
    return (
      <ExternalButtonLink href={to} {...rest}>
        {children}
      </ExternalButtonLink>
    );
  }

  if (isNoStyle) {
    return (
      <InternalButtonLinkNoStyle to={to} {...rest}>
        {children}
      </InternalButtonLinkNoStyle>
    );
  }

  return (
    <InternalButtonLink to={to} {...rest}>
      {children}
    </InternalButtonLink>
  );
};

export const ButtonIconLeft = styled.span`
  display: inline-flex;
  padding-right: 1rem;
  fill: var(--button-color);
`;

export const ButtonIconRight = styled.span`
  display: inline-flex;
  padding-left: 1rem;
  fill: var(--button-color);
`;
