import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import { completeSubscriptionRequest } from '../utils/lambda/stripe-retrive';

const Container = styled.div`
  position: relative;
  display: grid;
  padding-top: 2rem;
`;

const Text = styled.p`
  font-size: 1rem;
  font-weight: 400;
`;

const SecondText = styled.p`
  font-weight: 500;
  font-size: 1.2rem;
  padding-top: 1rem;

  &.link{
    padding-bottom: 1rem;
    font-weight: bold;
    font-size: 1.4rem;
  }
`;

const SuccessPage = () => {

  useEffect(() => {
    handleCompleteSubscription();
  }, []);

  const handleCompleteSubscription = async () => {
    const params2 = new URLSearchParams(window.location.search);
    const id = params2.get('session_id');
    if (id) {
      const response = await completeSubscriptionRequest(id);
      if (response.success) {
        console.log('Susbcription completed.');
      }
      else {
        console.error(response.error);
      }
    }
  };

  return (
    <Layout>
      <SEO
        title="Subscription success"
        meta={[
          {
            name: 'robots',
            content: 'noindex',
          },
        ]}
      />
      <Container>
        <h1>Thanks for purchasing</h1>
        <Text>
          Hi! Its great to have you join us! We are as excited as you and with no doubt we will be gaining greater clarity and control of our manufacturing operations and help take your company to the next level!
        </Text>
        <SecondText className='link'>
          <a href={ process.env.APP_OPEROUTE_URL }>Click here to go to the app</a>
        </SecondText>
        <Text>
          Please refer to the following user manual for more cool information: <Link to="/documentation">https://www.operoute.com/documentation</Link>
        </Text>
        <Text>
          Feel free to reach out to us via our contact page... <Link to="/#contact">https://www.operoute.com/#contact</Link>
        </Text>        
        <SecondText>
          Thank you and keep on rocking!
        </SecondText>
      </Container>
    </Layout>
  );
}

export default SuccessPage;
