import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  DARK_GRAY_COLOR,
  GRAY_COLOR,
  WHITE_COLOR,
  YELLOW_COLOR,
  DANGER_COLOR,
  mapColor,
} from './color';

export const InputNoStyle = styled.input`
  appearance: none;
  border: none;
  background-color: transparent;
`;

export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  column-gap: 0.875rem;
  row-gap: 0.275rem;
  align-items: center;
  min-width: 70px;
`;

export const SelectContainer = styled(InputContainer)`
  grid-template-columns: auto;
  /* &::after {
    content: '';
    background-image: inline(
      '../../icons/chevron-down.svg'
    );
    background-repeat: no-repeat;
    background-size: contain;
    width: 0.5rem;
    height: 0.5rem;
    margin-left: -1.5rem;
    pointer-events: none;
    z-index: 1;
  } */
`;

export const TextAreaContainer = styled(InputContainer)`
  display: flex;
  align-items: flex-start;
  min-width: 60px;
`;

export const Label = styled.label`
  width: 100%;
  column-width: 120px;
  font-size: 0.75rem;
  font-weight: bold;
`;

export const InputIcon = styled.div``;

const inputStyles = (props) => css`
  position: relative;
  width: 100%;
  max-width: 320px;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-family: var(--primary-font);
  background-color: ${WHITE_COLOR};
  border: ${props.borderless ? '0' : `1px solid ${GRAY_COLOR}`};
  border-radius: 0;
  box-sizing: border-box;

  &:focus {
    outline-color: ${YELLOW_COLOR};
  }

  &:not(:placeholder-shown):not(:focus):invalid {
    border: 2px solid ${DANGER_COLOR};
  }
`;

export const Input = styled.input`
  ${inputStyles};

  ${props => props.valid === 'false' && css `
    border: 2px solid ${DANGER_COLOR};
  `}
`;

export const Checkbox = styled(Input)`
  appearance: none;
  margin: 0;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;

  &:checked {
    background-color: var(--white-color);

    &::before {
      content: '\\2714';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /* font-size: 0.875rem; */
      font-size: 1rem;
      color: ${DARK_GRAY_COLOR};
    }
  }
`;
export const Select = styled.select`
  ${inputStyles};
`;

export const TextArea = styled(Input)`
  min-height: 5rem;
  max-height: 6rem;
`;

export const InputHelperText = styled.span`
  grid-column: 2 / 3;
  font-size: 0.75rem;
  color: ${mapColor()};
`;

// .input__icon {
//   display: inline-flex;
//   height: 2.5rem;
//   width: 2.5rem;
//   justify-content: center;
//   background-color: var(--gray-color);
//   border: 1px solid var(--gray-color);
//   box-sizing: border-box;
// }

// .input--has-icon {
//   max-width: calc(320px - 2.5rem);
// }
