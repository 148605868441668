import React from 'react';
import styled from '@emotion/styled';
import { Heading1, Heading3 } from '../../common/typography';
import { breakpoints as bp } from '@operoute/ui';
import Planning from './planning';
import Parts from './parts';
import PurchaseOrders from './purchase-orders';
import RouteCard from './route-card';
import WorkOrders from './work-orders';
import {
  EndDot,
  HeadingContainer,
  LineContainer,
  VerticalLine,
} from './common';
import { Link } from 'gatsby';

const Section = styled.section`
  display: grid;
  row-gap: 0.5rem;

  ${bp[0]} {
    row-gap: 1rem;
  }
`;

const HeadingModules = styled(Heading1)`
  justify-self: center;
`;

const SubHeadingModules = styled(Heading3)`
  justify-self: center;
  max-width: 400px;
  text-align: center;
`;

const ModulesContainer = styled.div`
  display: grid;
  row-gap: 2rem;
  margin-top: 5rem;

  ${bp[2]} {
    row-gap: 3rem;
  }

  ${bp[3]} {
    row-gap: 5rem;
  }
`;

const ModulesBlock = () => {
  return (
    <Section id="modules">
      <HeadingModules>Operoute Core</HeadingModules>
      <SubHeadingModules color="dark_gray">
        Generate real-time production data pertaining to your assets.
      </SubHeadingModules>
      <ModulesContainer>
        <Planning />
        <Parts />
        <PurchaseOrders />
        <RouteCard />
        <WorkOrders />
      </ModulesContainer>
    </Section>
  );
};

export default ModulesBlock;
