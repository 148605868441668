import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { Link as GatsbyLink } from 'gatsby';
import AuthLayout from '../components/auth-layout';
import SEO from '../components/seo';
import {
  Button as CommonButton,
  GRAY_COLOR_RGBA,
  LIGHT_GRAY_COLOR,
  PRIMARY_COLOR,
  NotificationsContext,
  Input,
  Label,
  InputContainer,
  breakpoints as bp,
} from '@operoute/ui';

const Header = styled.div`
  display: inline-flex;
  align-items: flex-end;
  justify-content: space-between;
  box-sizing: border-box;

  ${bp[0]} {
    align-items: baseline;
  }
`;

const Content = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 90%;
  align-items: center;
  align-content: center;
  row-gap: 1rem;
  padding: 0.5rem;
  border: 1px solid ${GRAY_COLOR_RGBA(0.8)};
  background-color: ${LIGHT_GRAY_COLOR};

  ${bp[0]} {
    row-gap: 2rem;
    padding: 1rem;
  }
`;

const Button = styled(CommonButton)`
  align-self: flex-end;
  justify-content: center;
`;

const SmallText = styled.span`
  max-width: 160px;
  font-size: 0.875rem;
  text-align: start;

  ${bp[0]} {
    max-width: 240px;
  }

  ${bp[2]} {
    max-width: 260px;
  }
`;

const Link = styled(GatsbyLink)`
  --link-color: ${PRIMARY_COLOR};
`;

const FormLabelInput = styled(Label)`
  max-width: 60px;
`;

const Form = styled.form`
  display: grid;
  grid-template-rows: auto 1fr auto;
  row-gap: 1rem;
  width: 95%;
  min-height: 320px;
  padding: 1rem;
  box-sizing: border-box;

  ${bp[0]} {
    row-gap: 1.5rem;
    width: 460px;
    min-height: 400px;
    padding: 2rem;
  }
`;

const LoginPage = () => {
  const { addNotification } = useContext(NotificationsContext);
  const [state, setState] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(state);
    addNotification({
      message: ``,
    });
    // if success
    // redirect to app
    // else
    // show error as notifications
  };

  return (
    <AuthLayout>
      <SEO title="Login" />
      <Form onSubmit={handleSubmit}>
        <Header>
          <strong>Log in</strong>
          <SmallText>
            Or <Link to="/signup">Subscribe</Link> if you don't have an account
          </SmallText>
        </Header>
        <Content>
          <InputContainer>
            <FormLabelInput htmlFor="email">Email</FormLabelInput>
            <Input onBlur={handleChange} name="email" type="email" />
          </InputContainer>
          <InputContainer>
            <FormLabelInput htmlFor="password">Password</FormLabelInput>
            <Input onBlur={handleChange} name="password" type="password" />
          </InputContainer>
        </Content>
        <Button type="submit" color="white" bgcolor="primary">
          Log in
        </Button>
      </Form>
      <span>Ver. 1.0</span>
    </AuthLayout>
  );
};

export default LoginPage;
