import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`Our PLM Software solution helps manufacturing companies who want to better track their product in the shop floor by providing clear and concise data to users so they can follow the right and approved operating procedures and work instructions
and visuals to feed into their work orders to further increase quality of what is being produced.`}</p>
    <p>{`We unlike most PLM are industry specific and follow best practices and mechanisms for validation of work and follow through used by modern aerospace manufacturing companies today.`}</p>
    <h2 {...{
      "id": "main-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#main-features",
        "aria-label": "main features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Main features`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Parts:`}</strong>{` Manage the inventory for the materials that will be produced as well as their properties.`}</li>
      <li parentName="ul"><strong parentName="li">{`Purchase Orders:`}</strong>{` Capture and manage the receipts and exits of part orders.`}</li>
      <li parentName="ul"><strong parentName="li">{`Work orders:`}</strong>{` Capture and update the client's work orders.`}</li>
      <li parentName="ul"><strong parentName="li">{`Operations:`}</strong>{` Configure operations, including the lates operation standards, work instructions, Tooling list, etc that will be linked to the route cards.`}</li>
      <li parentName="ul"><strong parentName="li">{`Route Cards:`}</strong>{` These are "Operation Guides" to walk through the users on every part that will be worked on.`}</li>
      <li parentName="ul"><strong parentName="li">{`Tool & Fixtures:`}</strong>{` Create and manage tool and fixtures catalog.`}</li>
      <li parentName="ul"><strong parentName="li">{`Work Instructions:`}</strong>{` Create and manage work instructions to use in your operations.`}</li>
      <li parentName="ul"><strong parentName="li">{`Operation Inputs:`}</strong>{` Create and manage user entry sections to use in our operations.`}</li>
      <li parentName="ul"><strong parentName="li">{`Reports:`}</strong>{` NCR and other production reports.`}</li>
      <li parentName="ul"><strong parentName="li">{`Catalogs:`}</strong>{` Create and manage all other items related to your operations details.`}</li>
      <li parentName="ul"><strong parentName="li">{`Packing:`}</strong>{` Create and mange finished packing list slip for finished work orders.`}</li>
      <li parentName="ul"><strong parentName="li">{`Planning:`}</strong>{` Create schedules for your entire production cycle.`}</li>
      <li parentName="ul"><strong parentName="li">{`Admin:`}</strong>{` Create, delete, modify users, assign roles for better control and security. Also define workflows for your operation.`}</li>
    </ul>
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      