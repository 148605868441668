import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  breakpoints as bp,
  PRIMARY_COLOR,
  PRIMARY_COLOR_RGBA,
  YELLOW_COLOR,
} from '@operoute/ui';
import { Heading3 } from '../../common/typography';

export const Number = styled.span`
  display: grid;
  align-content: center;
  width: 64px;
  height: 64px;
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
  font-family: var(--secondary-font);
  border: 2px solid ${PRIMARY_COLOR};
  border-radius: 100%;
  color: ${PRIMARY_COLOR};
  box-sizing: border-box;
`;

export const Title = styled.h2`
  margin: 0;
  font-family: var(--primary-font);
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1px;
`;

export const Text = styled(Heading3)`
  text-align: center;
`;

export const BlueText = styled(Heading3)`
  justify-self: center;
  align-self: center;
  max-width: 480px;
  color: ${PRIMARY_COLOR};
  font-size: 1.5rem;
  font-weight: 700;

  ${bp[1]} {
    justify-self: ${(props) => props.selfJustify || 'center'};
  }

  ${bp[2]} {
    max-width: 400px;
  }

  ${bp[3]} {
    max-width: 480px;
  }
`;

export const HeadingContainer = styled.div`
  display: grid;
  row-gap: 1.5rem;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
  max-width: 620px;
  margin: 0 auto;
  padding-bottom: 2rem;
`;

export const Container = styled.div`
  display: grid;
  row-gap: 0.5rem;

  ${bp[1]} {
    row-gap: unset;
  }
`;

export const ContentContainer = styled.div`
  display: grid;
  min-height: 400px;
  row-gap: 1.5rem;

  ${bp[1]} {
    row-gap: unset;
    grid-template-columns: 1fr 20px 1fr;
    min-height: 440px;
  }

  ${bp[3]} {
    min-height: 600px;
  }
`;

export const ImageContainer = styled.div`
  grid-row: 1/2;
  align-self: center;
  justify-self: center;
  max-width: 500px;
  width: 100%;

  ${bp[1]} {
    justify-self: ${(props) => props.selfJustify || 'center'};
    grid-row: unset;
  }
`;

export const HorizontalLine = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  margin: 0 auto;
  background-color: ${YELLOW_COLOR};
`;

export const LineContainer = styled.div`
  height: ${(props) => (props.height ? props.height : '100%')};
  min-height: 8rem;
  position: relative;
  grid-row: 3/4;

  ${bp[1]} {
    grid-row: unset;
  }
`;

export const VerticalLine = styled.div`
  position: relative;
  height: 100%;
  width: 2px;
  margin: 0 auto;
  background-color: ${YELLOW_COLOR};
`;

export const StartDot = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 16px;
  height: 16px;
  background-color: ${YELLOW_COLOR};
  transform: translate(-50%, -50%);
  border-radius: 100%;
  box-sizing: content-box;
`;

export const EndDot = styled(StartDot)`
  top: 100%;
`;

export const Dot = styled.div`
  display: none;
  position: absolute;
  top: ${(props) => props.top || '50%'};
  left: ${(props) => props.left || '50%'};
  width: 8px;
  height: 8px;
  background-color: ${YELLOW_COLOR};
  transform: translate(-50%, -50%);
  border-radius: 100%;
  box-sizing: content-box;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid ${YELLOW_COLOR};
    width: 16px;
    height: 16px;
    border-radius: 100%;
    transform: translate(-50%, -50%);
  }

  ${bp[1]} {
    display: block;
  }
`;

export const RelationLines = styled.div`
  display: none;
  position: absolute;
  top: ${(props) => props.top || '50%'};
  left: ${(props) => props.left || '50%'};
  transform: translate(-50%, -50%);

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: calc(4vw + 2px);
    height: 1px;
    border-top: 1px dashed ${PRIMARY_COLOR_RGBA(0.8)};
  }

  &::before {
    right: 16px;
    ${(props) => props.leftLine && css(props.leftLine)}
  }

  &::after {
    left: 16px;
  }

  ${bp[3]} {
    display: block;
  }
`;

export const BottomRelationLines = styled(RelationLines)`
  &::before {
    transform: rotate(90deg);
    right: 0;
    top: calc(100% + 16px);
    /* content: ''; */
    /* position: absolute; */
    /* right: unset; */
    /* top: 16px; */
    width: 60px;
    height: 60px;
    border-top: 1px dashed ${PRIMARY_COLOR_RGBA(0.8)};
  }

  &::after {
    content: unset;
  }
`;
