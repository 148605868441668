import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ButtonNoStyle, PRIMARY_COLOR } from '@operoute/ui';

const Container = styled.div`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  position: fixed;
  right: 16px;
  bottom: 16px;
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 23px solid transparent;
  border-right: 23px solid transparent;
  border-bottom: 40px solid ${PRIMARY_COLOR};
`;

const Button = styled(ButtonNoStyle)`
  display: grid;
  justify-items: center;
  row-gap: 4px;
  min-height: 60px;
  height: auto;
  padding: 4px;
`;

const ToTheTop = () => {
  const [show, setShow] = useState(false);

  const goTop = () => {
    window.scroll(0, 0);
  };

  useEffect(() => {
    const onScrollEvent = () => {
      const position = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      );
      setShow(position > 600);
    };

    window.addEventListener('scroll', onScrollEvent);
    return () => {
      window.removeEventListener('scroll', onScrollEvent);
    };
  }, []);

  return (
    <Container isVisible={show}>
      <Button onClick={goTop}>
        <Triangle />
        <span>Go to top</span>
      </Button>
    </Container>
  );
};

export default ToTheTop;
