import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const CancelPage = () => (
  <Layout>
    <SEO
      title="Canceled Purchase"
      meta={[
        {
          name: 'robots',
          content: 'noindex',
        },
      ]}
    />
    <h1>Canceled Purchase</h1>
  </Layout>
);

export default CancelPage;
