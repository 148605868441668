import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import MainBlock from '../components/homepage/main';
import ModulesBlock from '../components/homepage/modules/index';
import PricingBlock from '../components/homepage/pricing';
import VideoBlock from '../components/homepage/videoglam';
import ToTheTop from '../components/to-the-top';

const IndexPage = () => {
  const { backgroundImage } = useStaticQuery(query);

  return (
    <Layout backgroundImage={backgroundImage}>
      <SEO title="Home" />
      <MainBlock />
      <VideoBlock />
      <ModulesBlock />
      <PricingBlock />
      <ToTheTop />
    </Layout>
  );
};

const query = graphql`
  query ProductsQuery {
    backgroundImage: imageSharp(
      resize: { originalName: { eq: "main-background.png" } }
    ) {
      resize(quality: 90, width: 800) {
        src
      }
    }
  }
`;

export default IndexPage;
