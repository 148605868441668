const config = {
  stripe: {
    publishableKey: process.env.GATSBY_STRIPE_PUBLISHABLE_KEY,
    basicPriceId: process.env.GATSBY_STRIPE_BASIC_PRICE_ID,
  },
  aws: {
    lambda: {
      url: process.env.GATSBY_AWS_LAMBDA_URL,
      xApiKey: process.env.GATSBY_AWS_X_API_KEY
    },
  },
};

export default config;
