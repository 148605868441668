export default {
  colors: {
    primary: '#1B98E0',
    background: '#FFFFFF',
    shape: `#F2F2FA`,
    title: `#3D3D4D`,
    text: `#6C6C80`,
    components: {
      blockquote: {
        background: `#332616`,
        text: `#E1E1E6`,
      },
    },
  },
  fonts: {
    fontFace: {
      family: 'Roboto',
      file: '/fonts/roboto-regular-webfont',
    },
    body: 'Roboto',
  },
};
