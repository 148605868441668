import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useTable } from 'react-table';
import { Button, ButtonIconLeft } from './common/button';
import { GRAY_COLOR, LIGHT_GRAY_COLOR, WHITE_COLOR } from './common/color';
import { ReactComponent as FilterSVG } from '../icons/filter.svg';
import { ReactComponent as SearchSVG } from '../icons/search.svg';

const StyledTable = styled.table`
  background-color: ${WHITE_COLOR};
  border: 1px solid ${GRAY_COLOR};
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  border: 1px solid ${GRAY_COLOR};
  border-collapse: collapse;
  text-align: start;
  box-sizing: border-box;
`;

const Tr = styled.tr`
  background-color: ${WHITE_COLOR};

  &:nth-child(even) {
    background-color: ${LIGHT_GRAY_COLOR};
  }
`;

const THead = styled.thead`
  ${Tr} {
    background-color: ${LIGHT_GRAY_COLOR};
  }
`;

const Td = styled.td`
  padding: 1rem;
  font-size: 0.75rem;
  border: 1px solid ${GRAY_COLOR};
  border-collapse: collapse;
  box-sizing: border-box;
`;

const SearchBar = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto auto 1fr;
  justify-content: start;
  column-gap: 1rem;
`;

const PaginationContainer = styled.div`
  display: inline-grid;
  align-items: center;
  justify-content: center;
  grid-auto-flow: column;
`;
const PaginationPrev = styled(Button)``;
const PaginationNext = styled(Button)``;
const PaginationButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
`;
const PaginationButton = styled(Button)<{ iscurrent?: boolean }>`
  ${(props) =>
    props.iscurrent &&
    css`
      border-color: var(--yellow-color);
      border-width: 2px;
    `}
`;

export const Table = ({
  actionButtons = [],
  withSearch = true,
  columns = {},
  data = [],
  withPagination = true,
  maxPaginate = 25,
}) => {
  return (
    <div>
      {withSearch && (
        <SearchBar>
          {actionButtons.map((ActionButton) => (
            <ActionButton />
          ))}
          <Button>
            <ButtonIconLeft>
              <FilterSVG />
            </ButtonIconLeft>
            Tottle Filtering
          </Button>
        </SearchBar>
      )}
      <StyledTable>
        <THead>
          <Tr>
            {Object.keys(columns).map((column) => (
              <Th>{columns[column]}</Th>
            ))}
          </Tr>
        </THead>
        <tbody>
          {data.map((row) => (
            <Tr>
              {Object.keys(row).map((column) => (
                <Td>{row[column]}</Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </StyledTable>
      {withPagination && (
        <PaginationContainer>
          <PaginationPrev>&#9668;</PaginationPrev>
          <PaginationButtonsContainer>
            <PaginationButton>1</PaginationButton>
          </PaginationButtonsContainer>
          <PaginationNext>&#9658;</PaginationNext>
        </PaginationContainer>
      )}
    </div>
  );
};

export default Table;
