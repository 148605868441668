import config from '../config';

export const stripeRequest = async (data) => {
  try {
    const { firstName, lastName, organizationName, email, plan } = data;

    const subscriptionRequest = await fetch(
      `${process.env.GATSBY_AWS_LAMBDA_URL}/subscription/stripe`,
      {
        method: 'POST',
        body: JSON.stringify({
          data: {
            TenantInfo: {
              FirstName: firstName,
              LastName: lastName,
              Email: email,
              OrganizationName: organizationName,
              Plan: plan,
            },
            Stripe: {
              priceId: process.env.GATSBY_STRIPE_BASIC_PRICE_ID,
              RedirectUrlSuccess: `${window.location.origin}/success`,
              RedirectUrlCancel: `${window.location.origin}/fail`
            }
          },
        }),
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.GATSBY_AWS_X_API_KEY
        },
      }
    );
    const response = await subscriptionRequest.json();
  
    return {
      success: response.success,
      data: response.data.stripe,
      error: 'error' in response ? response.error : null,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      error: err.message,
    };
  }
};
