import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link as GatsbyLink, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Paper } from './common/shape';
import { Heading3 } from './common/typography';
import {
  breakpoints as bp,
  DARKER_BLUE_COLOR,
  WHITE_COLOR,
  PRIMARY_COLOR,
  GlobalStyles,
} from '@operoute/ui';

const MainContainer = styled.main`
  position: relative;
  display: grid;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  row-gap: 2rem;
  width: 100%;
  min-height: 100vh;
  background-color: ${DARKER_BLUE_COLOR};

  ${bp[0]} {
    row-gap: 1rem;
    height: 100vh;
  }
`;

const TermsOfService = styled.span`
  padding-top: 3rem;
  color: ${WHITE_COLOR};
  font-size: 0.875rem;
  font-weight: 300;

  ${bp[0]} {
    padding-top: 1.5rem;
  }
`;

const Link = styled(GatsbyLink)`
  --link-color: ${PRIMARY_COLOR};
  text-decoration: underline;
`;

const PaperContainer = styled(Paper)`
  width: 95%;
  ${bp[0]} {
    width: 100%;
  }
`;

const AuthLayout = ({ children }) => {
  const { logo } = useStaticQuery(query);
  return (
    <MainContainer>
      <GlobalStyles />
      <GatsbyLink to="/">
        <Img fixed={logo.fixed} />
      </GatsbyLink>
      <Heading3 color="white">Welcome to Operoute</Heading3>
      <PaperContainer>{children}</PaperContainer>
      <TermsOfService>
        By signing up to Operoute you agree to our{' '}
        <Link to="/terms-of-service">terms of service</Link>
      </TermsOfService>
    </MainContainer>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

const query = graphql`
  query {
    logo: imageSharp(fixed: { originalName: { eq: "operoute-icon.png" } }) {
      fixed(width: 60) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;

export default AuthLayout;
